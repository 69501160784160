<template>
  <form
    ref="boxActuals"
    data-test="card-article-portioning-actuals"
    class="card validation"
    novalidate
    @submit.prevent>

    <div class="card-header">
      <h3 class="card-title">Article portioning actuals</h3>
    </div>

    <div class="card-body">
      <div data-test="group-week-from" class="form-group">
        <label class="form-label">Week</label>
        <div class="input-group">
          <weekly-menu-filter @on-change="onWeekChange" @init="setWeeklyMenus"/>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">Choose Delivery Date</label>
        <calendar-day-picker v-model="date"/>
      </div>
      <div v-if="errorMessage" class="invalid-feedback d-block">{{errorMessage}}</div>
      <div class="row">
        <div class="col-md-12">
          <small>Note: This export is only accurate if exported 2 days before production after the CLT cut-off.</small>
        </div>
      </div>
    </div>
    <div class="card-footer text-right">
      <button
        type="submit"
        data-test="btn-export-article-portioning-actuals"
        class="btn btn-primary"
        disabled
        @click="exportArticlePortioningActuals"
      >
        <!--        this below code should be replaced with disabled, we disabled this button because we temporarily wants to block users from download -->
        <!--        :disabled="submitting"-->
        Export Article Portioning Actuals
      </button>
    </div>
  </form>
</template>

<script>
import CalendarDayPicker from '@/components/CalendarDayPicker';
import WeeklyMenuFilter from '@/components/filters/WeeklyMenuFilter';
import download from '@/mixins/download';
import {articleForecast} from '@/services';
import submitting from '@hellochef/shared-js/mixins/submitting';
import moment from 'moment-timezone';

export default {
  components: {
    CalendarDayPicker,
    WeeklyMenuFilter,
  },
  mixins: [
    download,
    submitting,
  ],
  data() {
    return {
      weeklyMenus: [],
      weeklyMenu: null,
      date: {
        startDate: moment(),
      },
      errorMessage: null,
    };
  },
  computed: {
    selectedStartDate() {
      return this.weeklyMenus.find(weeklyMenu => weeklyMenu.id === this.weeklyMenu)?.startDate || '';
    },
  },
  watch: {
    selectedStartDate() {
      this.date = {
        startDate: moment(this.selectedStartDate),
      };
    },
  },
  methods: {
    onWeekChange(value) {
      this.weeklyMenu = value;
    },
    setWeeklyMenus(weeklyMenus) {
      this.weeklyMenus = weeklyMenus;
    },
    exportArticlePortioningActuals() {
      if (!this.weeklyMenu || !this.date) {
        return alert('You must choose weekly menu and delivery date');
      }

      this.submitIt(async () => {
        try {
          this.download(await articleForecast.portioningActualsExport({
            weekly_menu_id: this.weeklyMenu,
            delivery_date: moment(this.date.startDate).format('YYYY-MM-DD'),
          }));
        }
        catch (e) {
          this.errorMessage = 'There is no data to download';
        }
      });
    },
  },
};
</script>
